import React from "react";

import logo from "../assets/ApplicationLogo.png";

const Hero = () => (
  <div className="text-center hero my-3">
    <img className="mb-3 app-logo" src={logo} alt="PatTrac logo" width="240" />
    <h1 className="mb-1">Form Builder Project</h1>
    <p className="lead">
      This application allows you to create form designs to be used in PatTrac's Web Forms.
    </p>
  </div>
);

export default Hero;
