const contentData = [
  {
    title: "Request Access",
    link: "https://support.pattrac.info",
    description:
      "The form build will be available to all users with a valid account. If you do not have an account, please request access via the PatTrac Support Site."
  },
  {
    title: "Copy your form's JSON output to a Web Form.",
    // link: "https://auth0.com/docs/multifactor-authentication",
    description:
      "Create a Web Form in PatTrac and paste the JSON output from the form builder into the Web Form's JSON field."
  },
  // {
  //   title: "Anomaly Detection",
  //   link: "https://auth0.com/docs/anomaly-detection",
  //   description:
  //     "Auth0 can detect anomalies and stop malicious attempts to access your application. Anomaly detection can alert you and your users of suspicious activity, as well as block further login attempts."
  // },
  // {
  //   title: "Learn About Rules",
  //   link: "https://auth0.com/docs/rules",
  //   description:
  //     "Rules are JavaScript functions that execute when a user authenticates to your application. They run once the authentication process is complete, and you can use them to customize and extend Auth0's capabilities."
  // }
];

export default contentData;
