import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { SurveyCreatorComponent, SurveyCreator, PanelAdornerComponent } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { setLicenseKey } from "survey-core";

export const FormBuilderComponent = () => {
  
  setLicenseKey(
    "YjJiZDlmZTktMzY2YS00NDQ3LThiMmYtMmY4YjhhZjcxYjhjOzE9MjAyNS0wNC0yMiwyPTIwMjUtMDQtMjIsND0yMDI1LTA0LTIy"
  );

  const creatorOptions = {
    showLogicTab: true,
    isAutoSave: true,
    showThemeTab: true,
    showResults: true,
    PanelAdornerComponent

  };
  
  const creator = new SurveyCreator(creatorOptions);

  return (
    <>
    <SurveyCreatorComponent creator={creator} />
    </>
  );
};

export default withAuthenticationRequired(FormBuilderComponent, {
  onRedirecting: () => <Loading />,
});
